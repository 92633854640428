import { render, staticRenderFns } from "./ComponentRouting.vue?vue&type=template&id=8d308fee&scoped=true"
import script from "./ComponentRouting.vue?vue&type=script&lang=js"
export * from "./ComponentRouting.vue?vue&type=script&lang=js"
import style0 from "./ComponentRouting.vue?vue&type=style&index=0&id=8d308fee&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d308fee",
  null
  
)

export default component.exports